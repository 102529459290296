import React, {useState} from "react";
import imageOverlay from "../img/earth.jpg";
import emailjs from 'emailjs-com';  

const Contact = (props) =>{

  const [fromName, setFromName] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [sentStatus, setSentStatus] = useState(0);

 
 const handleSendMailClick = (e) => {
   if(fromName===''){
     alert("Your name required!");
   }else if(fromEmail===''){
    alert("Your email required!");
  }else if(subject===''){
    alert("Subject required!");
  }else if(message===''){
    alert("Message required!");
  }else{
    const templateParams = {
      from_name: fromName,
      from_email: fromEmail,
      subject: subject,
      message: message
    };
    
    emailjs.send('service_phannam','template_phannam', templateParams, 'user_em1tr5IeMtA1om0eqlH6n')
    .then((response) => {
      //console.log('SUCCESS!', response.status, response.text);
      setSentStatus(1);
      setTimeout(function(){
        setSentStatus(0);
        setFromName("");
        setFromEmail("");
        setSubject("");
        setMessage("");
      }, 3000);
    }, (err) => {
      //console.log('FAILED...', err);
      setSentStatus(2);
      setTimeout(function(){
        setSentStatus(0);
        setFromName("");
        setFromEmail("");
        setSubject("");
        setMessage("");
      }, 3000);
    });
    
  }
    
  } 
    return (
      <section
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        <div className="overlay-mf"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2">
                        <h5 className="title-left">Send A Message</h5>
                      </div>
                      <div>
                        <form
                          action="https://phannam.com"
                          method="POST"
                          className="contactForm"
                        >
                          <div id="sendmessage" style={sentStatus===1?{display:"inline-block"}:{display:"none"}}>
                            Your message has been sent. Thank you!
                          </div>
                          <div id="errormessage" style={sentStatus===2?{display:"inline-block"}:{display:"none"}}>Error, please try again</div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  id="name"
                                  placeholder="Your Name"
                                  data-rule="minlen:4"
                                  value={fromName}
                                  data-msg="Please enter at least 4 chars"
                                  onChange={e => setFromName(e.target.value)}
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                  value={fromEmail}
                                  placeholder="Your Email"
                                  data-rule="email"
                                  data-msg="Please enter a valid email"
                                  onChange={e => setFromEmail(e.target.value)}
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subject"
                                  id="subject"
                                  placeholder="Subject"
                                  value={subject}
                                  data-rule="minlen:4"
                                  data-msg="Please enter at least 8 chars of subject"
                                  onChange={e => setSubject(e.target.value)}
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="message"
                                  rows="5"
                                  value={message}
                                  data-rule="required"
                                  data-msg="Please write something for us"
                                  placeholder="Message"
                                  onChange={e => setMessage(e.target.value)}
                                ></textarea>
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button
                                type="button" 
                                onClick={(e) => handleSendMailClick(e)}
                                className="button button-a button-big button-rouded"
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Get in Touch</h5>
                      </div>
                      <div className="more-info">
                        <p className="lead">
                          Whether you want to get in touch, talk about a project collaboration, or just say hi, I'd love to hear from you.
                          <br />
                          Simply fill the from and send me an email.
                        </p>
                        <ul className="list-ico">
                          <li><span className="ion-ios-location"></span> K155/119/08 Tran Dinh Nam, Hoa An, Cam Le, Da Nang</li>
                          <li><span className="ion-ios-telephone"></span> (+84) 977 496 798</li>
                          <li><span className="ion-email"></span> phannam88@gmail.com</li>
                        </ul> 
                      </div>
                      <div className="socials">
                        <ul>
                          <li>
                            <a
                              href="https://fb.me/phannamit"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-facebook"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/phannamit"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-twitter"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/phannamit"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-linkedin"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://github.com/phannamqt"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="ico-circle">
                                <i className="ion-social-github"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  } 

export default Contact;

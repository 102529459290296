import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_CSS3_skill", content: "HTML5/CSS3", porcentage: "75%", value: "75" },
        {
          id: "JavaScript_skill",
          content: "JavaScript",
          porcentage: "75%",
          value: "75"
        },
        { id: "PHP_skill", content: "PHP/Laravel", porcentage: "80%", value: "80" },
        {
          id: "Wordpress_skill",
          content: "Wordpress",
          porcentage: "75%",
          value: "75"
        },{
          id: "ReactJS_skill",
          content: "React JS",
          porcentage: "75%",
          value: "75"
        },{
          id: "ReactNative_skill",
          content: "React Native",
          porcentage: "70%",
          value: "70"
        },
        {
          id: "Java_skill",
          content: "Java",
          porcentage: "70%",
          value: "70"
        },{
          id: "Cshape_skill",
          content: "C#",
          porcentage: "70%",
          value: "70"
        },{
          id: "mssql_skill",
          content: "SQL Server",
          porcentage: "80%",
          value: "80"
        },{
          id: "git_skill",
          content: "Git",
          porcentage: "75%",
          value: "75"
        }
      ],
      about_me: [
        {
          id: "first-p-about",
          content:
            "I come from Vietnam. I was born in Quang Tri province, and now I live & work in Da Nang city. I have 10 years experience of working in software company (6 year in position develop, 4 year in position project manager and technical manager)."
        }
      ],
      education: [
        {
          id: "first-p-education",
          time:"2009 - 2013",
          title: "DANANG COLLEGE TECHNOLOGY", 
          content:
            "Major: Information technology"
        }
      ],
      work_xperience: [
        {
          id: "first-p-work_xperience",
          time:"June 2019 - Present",
          title: "TEAM LEADER", 
          desc: "Developer, team leader",
          content:[
            {id: "2", row: "- Developer and team leader."},
            {id: "3", row: "- Analysis and design solutions from requirements."},
            {id: "4", row: "- Implement designed solution."},
            {id: "5", row: "- Optimize code."},
            {id: "6", row: "- Review code."},
          ],
        },
        {
          id: "second-p-work_xperience",
          time:"Nov 2013 - Jun 2019",
          title: "DEVELOPER",
          desc: "Developer",
          content:[
            {id: "12", row: "- Developer."},
            {id: "14", row: "- Maintain and develop new module."},
            {id: "14", row: "- Implement designe solution."},
          ] 
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" key='about' className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="box-shadow-full"> 
                <div className="row">
                <div className="col-md-12">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">About Me</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <img src="https://avatars.githubusercontent.com/u/17335325?v=4"
                          className="rounded mx-auto d-block myavatar" alt="Phan Nam"  
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row my-info">
                            <div className="col-xl-12">
                            <h6 className="title-m lead">Full Stack Developer</h6>
                            </div> 
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Date of birth:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            Sep 14, 1988
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Gender:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            Male
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Phone:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            <a target="_blank" href="http://zalo.me/0977496798">(+84) 977 496 798</a>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Email:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            phannam88@gmail.com
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Facebook:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            <a target="_blank" href="https://fb.me/phannamit">fb.me/phannamit</a>
                            </div>
                          </div> 
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            Linkedin:
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            
                            <a target="_blank" href="https://linkedin.com/in/phannamit">linkedin.com/in/phannamit</a>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-3 lead">
                            City: 
                            </div>
                            <div className="col-xl-9 col-md-9 lead">
                            Da Nang, Vietnam
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12"> 
                          <h6 className="title-s">Some Words About Me </h6>
                        </div>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="skill-mf">
                      <div className="row">
                        <div className="col-md-12"> 
                          <h6 className="title-s">Skills</h6>
                        </div>
                      </div>
                      {this.state.skills.map(skill => {
                        return (
                          <>
                            <React.Fragment key={skill.id}>
                              <span>{skill.content}</span>
                              <span className="pull-right">
                                {skill.porcentage}
                              </span>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: skill.porcentage }}
                                  aria-valuenow={skill.value}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </React.Fragment>

                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6"> 
                    <div className="skill-mf">
                      <div className="row">
                        <div className="col-md-12"> 
                          <h6 className="lead title-s">Education</h6>
                        </div>
                      </div>
                      {this.state.education.map(content => {
                        return (
                          <>
                              <div className="row" >
                                <div className="col-md-12">
                                  <p className="lead title-m sub-content">{content.title}</p> 
                                </div>
                              </div>
                              <div className="row" >
                                <div className="col-md-12">
                                   <p className="lead time sub-content">
                                    <span className="badge rounded-pill bg-light text-dark">{content.time}</span>
                                  </p>
                                  
                                  <p className="lead sub-content">
                                    {content.content}
                                  </p>
                                </div>
                              </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="skill-mf">
                      <div className="row">
                        <div className="col-md-12"> 
                          <h6 className="title-s">Work Experience</h6>
                        </div>
                      </div>
                      {this.state.work_xperience.map(content => {
                        return (
                          <>
                            <div className="row" key={content.id}>
                              <div className="col-md-12">
                                <p className="lead title-m sub-content">{content.title}</p> 
                              </div>
                            </div>
                            <div className="row"  >
                                <div className="col-md-12">
                                  <p className="lead time sub-content mt-0">
                                   <span className="badge rounded-pill font-italic">{content.desc}</span>
                                   </p>
                                   <p className="lead time sub-content">
                                   <span className="badge rounded-pill bg-light text-dark">{content.time}</span>
                                  </p>
                                </div>
                              </div>
                            {content.content.map(row => {
                                  return (
                                    <div className="row" key={row.id} >
                                      <div className="col-md-12">
                                        <p className={"content-m sub-content content-"+row.id}>
                                          {row.row}
                                        </p>
                                      </div>
                                    </div>
                                );
                              })} 
                        </> 
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
